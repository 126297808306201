import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby"; /* eslint-disable */

const productBox = props => {
  const product = props.product;
  return (
    <div className="box productBox" key={product.node.title}
      style={{
        position: 'relative',
        boxShadow: '0px 3px 8px -4px rgba(0,0,0,0.15)',
        height: '100%',
        borderRadius: 0
      }}>
      <Link to={`/product/${product.node.handle}`}>
        <p className="has-text-weight-semibold has-text-black" style={{marginBottom: '10px'}}>
          {product.node.title}
        </p>
        <Img
          fluid={product.node.images[0].localFile.childImageSharp.fluid}
          key={product.node.images[0].localFile.id}
          alt={product.node.title}
          style={{marginBottom: '10px'}}
        />
        <div className="columns">
          <div className="column has-text-right has-text-weight-bold is-size-5 has-text-black"
            style={{
              marginLeft: 'auto'
            }}>
            ${product.node.variants[0].price}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default productBox;
